import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Main from "../../components/layout/main"
import Seo from "../../components/seo/seo"
import Section from "../../components/containers/section"
import SectionBackground from "../../components/containers/section-background"
import SectionContent from "../../components/containers/section-content"
import { Columns, Content, Rows } from "../../components/grid"
import Copy from "../../components/content/copy"
import parse from "html-react-parser"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar } from "@fortawesome/free-solid-svg-icons"

function ThanksPage({ data }) {
  const { seo, slug, title, postTitleText, calendarLink } =
    data.strapiThanksPages

  const seoObj = {
    title: seo.metaTitle,
    description: seo.metaDesc,
    image: seo.shareImage.localFile.publicURL,
    imageAlt: seo.shareImage.alternativeText,
    url: `/${slug}/`,
  }

  return (
    <Main hidenav>
      <Seo customData={seoObj} />
      <div className="height--screen flex flex--column">
        <Section style={{ flexGrow: 1 }} theme="dark">
          <SectionBackground>
            <div className="hero-image--tybg height--full width--full" />
            <StaticImage
              src="../../images/thank-you/rocket-launch.png"
              className="absolute ship"
              style={{ right: 0, bottom: 0, transform: "scaleX(-1)" }}
            />
          </SectionBackground>
          <SectionContent width="full" paddingBot="xxs" paddingTop="xxs">
            <Rows>
              <Columns count="1,2" className="mx-sm lap-mx-lg">
                <Content
                  paddingLeft="none"
                  paddingRight="none"
                  className="tab-mr-sm pc-mr-none tab-mt-md"
                  style={{ maxWidth: 400 }}
                >
                  <h1 className="huge">{parse(title)}</h1>
                  <Copy contentToParse={postTitleText} />
                  <div className="mt-xs tab-mt-md">
                    <div className="flex">
                      <FontAwesomeIcon
                        className="text--color-yellow text--24 mr-xxs"
                        icon={faStar}
                      />
                      <FontAwesomeIcon
                        className="text--color-yellow text--24 mr-xxs"
                        icon={faStar}
                      />
                      <FontAwesomeIcon
                        className="text--color-yellow text--24 mr-xxs"
                        icon={faStar}
                      />
                      <FontAwesomeIcon
                        className="text--color-yellow text--24 mr-xxs"
                        icon={faStar}
                      />
                      <FontAwesomeIcon
                        className="text--color-yellow text--24"
                        icon={faStar}
                      />
                    </div>
                    <p className="my-xs text--xl text--strong">
                      “Lunio is an excellent tool that is easy to use”
                    </p>
                    <p className="text--strong opc-75">Brad M, Head Marketer</p>
                  </div>
                </Content>
              </Columns>
            </Rows>
          </SectionContent>
        </Section>
      </div>
    </Main>
  )
}

export const query = graphql`
  query ThanksPageQuery($id: String!) {
    strapiThanksPages(id: { eq: $id }) {
      slug
      seo {
        metaTitle
        metaDesc
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      title
      postTitleText
    }
  }
`

export default ThanksPage
